//== [ 共通読み込み ]=============
//変数・mixin
@use 'module/variables' as *;
//===============================
//== [ モジュール読み込み ]========
// math（除算に[/]は使えない）
@use "sass:math";
//===============================

@mixin smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-print-color-adjust: exact;
}

/* topContents
----------------------------------------------- */
.topContents {
  padding-top: 3rem;
  padding-bottom: 3rem;
  position: relative;
  z-index: 1;

  // [インナー]
  &__inner {
    padding-top: 20px;
    padding-left: calcVw(32);
  }

  // [タイトル]
  &__title {
    position: relative;
    display: flex;
    align-items: baseline;
    margin-left: calcVw(32);
    padding-left: 16px;
    &::after{
      content: "";
      position: absolute;
      top: calcVw390(18);
      left: 0;
      display: block;
      width:  7px;
      height: 7px;
      background-color: $zoffBlue;
    }
    &--en {
      padding-right: 16px;
      font-size: 2.6rem;
      @include font-en;
      @include smoothing;
      line-height: math.div(40,26);
    }
    &--ja {
      font-size: 1.2rem;
      line-height: math.div(14,12);
    }
  }

   // [セパレーター]
  &__separate {
    &::before {
      content: "";
      display: block;
      width: 100%;
      border-top: 1px solid #000;
    }
  }

  // [ボタン]
  /*
  &__button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    min-width: 200px;
    min-height: 55px;
    border: 1px solid #000;
    border-radius: 55px;
    font-size: 1.3rem;
    background: #fff;
    color: #000;
    &Wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 39px;
    }
    &::after {
      content: "";
      width: 8px;
      height: 8px;
      margin-left: 12px;
      border-top: 1px solid #000;
      border-right: 1px solid #000;
      transform: rotate(45deg);
    }
  }
  */
  &__button {
    &Wrapper {
      padding-right: 5vw;
      padding-top: 39px;
      text-align: center;
    }
    @include linkButtonArrow(#000,$pcArrowRect:6);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-width: 200px;
    min-height: 55px;
    color: #000;
    font-size: 1.3rem;
    font-weight: 500;
    border: 1px solid #000;
    background-color: #fff;
    border-radius: 100vmax;
    &:after {
      margin: 0 -13px 0 9px;
    }
  }

}