//== [ 共通読み込み ]=============
//変数・mixin
@use 'module/variables' as *;
//===============================
//== [ モジュール読み込み ]========
// math（除算に[/]は使えない）
@use "sass:math";
//===============================

/* topMagazine
----------------------------------------------- */
.topMagazine {
  padding: calc(40 / 375 * 100vw) 0;
  background: $colorBg;

  &__inner {
    padding-left: calcVw(32);
    padding-right: calcVw(32);
  }

  &__list {
    flex-direction: column;
    &Control {
      display: none;
    }

    &Item {
      &:not(:first-child) {
        padding-top: 23px;
      }

      &:nth-child(n+3) {
        display: none;
      }

      &Head {
        display: flex;
        justify-content: space-between;
        padding-top: 10px;
        padding-bottom: calc( 8 / 390 * 100vw );

        &--date {
          @include font-en;
        }

        &--category {
          display: flex;
          align-items: center;
          padding: 2px 8px;
          font-size: 1.2rem;
          @include font-en;
          line-height: 1;
          color: #fff;
          background: $zoffBlue;
        }

        &Text {
          padding-top: 8px;
          line-height: math.div(14, 12);
          font-size: 1.5rem;
          text-align: left;
        }
      }

      &Face {
        img {
          object-fit: cover;
          height: calc(239 / 390 * 100vw);
          width: 100%;
        }
      }

    }
  }
}