//== [ 共通読み込み ]=============
//変数・mixin
@use 'module/variables' as *;
//===============================
//== [ モジュール読み込み ]========
// math（除算に[/]は使えない）
@use "sass:math";
//===============================

/* topRanking
----------------------------------------------- */
.topRanking {
  &__inner {
    padding-left: 0;
  }
  .searchResults {
    width: 100%;
    padding-left: 16px;
  }
  &__tabList {
    display: flex;
    align-items: center;
    margin-left: calcVw(32);
    margin-right: calcVw(32);
    background: $colorBg;
    border-radius: 40px;
    &Item {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
      height: 42px;
      &.js-current {
        background-color: #000;
        color: #fff;
        border-radius: 40px;
        opacity: 1;
      }
    }
  }

  // [ タブコンテンツ ]
  @keyframes animRankingFadein {
    0% {
      opacity: 0;
      filter: blur(20px);
    }
    100% {
      opacity: 1;
      filter: blur(0);
    }
  }

  &__tabContents {
    display: none;
    @include transition(.4s,ease,0s);
    &.js-current {
      display: block;
      animation: animRankingFadein .5s ease;
    }
  }
  // [ API出力 ] @override search_results.min.css
  .itemList {
    &__container {
      overflow-x: scroll;
      flex-wrap: nowrap;
      &--2col {
        .itemList {
          &__contents {
            margin-right: 15px;
            padding: 0;
            flex: 0 0 math.div(200,390)*100vw;
          }
        }
      }
    }
    &__contents {
      @for $i from 1 through 10 {
        .foo-#{$i} { width: 10px * $i; }
        &:nth-child(#{$i}){
          .itemList__imgWapper::before,
          .itemList__imgWrapper::before{
            content: "#{$i}";
            @if $i == 1 {
              color: #CBBC28;
            }@else if $i == 2 {
              color: #9F9F9F;
            }@else if $i == 3 {
              color: #99702A;
            }@else{
              color: #D3D3D3;
            }
          }
        }
      }
    }

    // [ 順位/個別 ]
    &__img{
      &Wapper,&Wrapper{ //@typo 正しいスペルも記載しておく
        padding-bottom: 17px;
        position: relative;

        // [ 順位/基本 ]
        &::before{
          content: "1";
          font-size: 2.5rem;
          font-weight: $fwMid;
          font-family: $font-en;
          position: absolute;
          left: 5px;
          top: -2px;
          line-height: math.div(33,25);
          z-index: 2;
        }
      }
      &Contents {
        width: 154px;
        margin-right: auto;
        margin-left: auto;
      }
    }
    &__name {
      font-size: 1.5rem;
      text-align: left;
      font-family: $font-ja;
    }
    &__price {
      font-size: 1.7rem;
      text-align: left;
      font-family: $font-en;
    }
    &__tax {
      font-size: 1rem;
      font-weight: 500;
      font-family: $font-ja;
    }
    &__code {
      text-align: left;
      font-family: $font-en;
      color: $colorTextGray;
    }
  }
}