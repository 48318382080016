//== [ 共通読み込み ]=============
//変数・mixin
@use 'module/variables' as *;
//===============================
//== [ モジュール読み込み ]========
// math（除算に[/]は使えない）
@use "sass:math";
//===============================

/* topCampaignBanner
----------------------------------------------- */
.topCampaignBanner {
  $banner:&;
  //
  &__inner {
    padding-top: 20px;
    text-align: center;
  }
  &__title{
  }
  &__listControl{
    display: flex;
    justify-content: space-between;
    gap: 16px;
    padding-right: calcVw(32);
    overflow-x: scroll;
  }
  &__listItem {
    flex: 0 0 calc( 334 / 390 * 100vw);
    a {
      display: flex;
      position: relative;
      &.is--border {
        &::after {
          content: "";
          display: block;
          position: absolute;
          top:0;
          width: 100%;
          height: 100%;
          border: 1px solid $colorBorderGray;
        }
      }
    }
    img {
        min-height: calc( 192 / 390 * 100vw );
    }
  }
  &__control{
    display: none;
  }
  // [ SWiper ]
  .swiper-container{
    #{$banner}{
      &__list{
        min-height: math.div(230,1360)*100vw;
        overflow-x: visible;
        gap: 0;
        &Item{
          margin-left: 0;
          flex: none;   
        }
      }
    }
    + #{$banner}{
      &__listControl{
        display: inline-flex;
        justify-content: center;
        position: relative;
        padding: 0 48px;
        overflow: visible;

        #{$banner}{
          &__listControlButton {
            background-image: none;
            margin: 0;
            display: flex;
            border: 1px solid #000;
            height: calcVw390(24);
            width: calcVw390(24);
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            position: absolute;
            @include linkButtonCircleArrow(#000,$spArrowRect:5);
            &--prev{
              transform: scale(-1);
              left: 0;
              right: auto;
            }
            &--next{
              left: auto;
              right: 0;
  
              &::after{
              }
            }
          }
          &__pagination {
            padding: 24px 0 0;
            position: relative;
            display: flex;
            justify-content: center;
            width: 100%;
            gap: 12px;
          }
          .swiper-pagination-bullet {
            height: 10px;
            width: 10px;
            border-radius: 50%;
            margin: 0 12*.5px;
          }
          .swiper-pagination-bullet-active {
            background: $zoffBlue;
          }
        }
      }
    }
  }
  //
}