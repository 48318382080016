//== [ 共通読み込み ]=============
//変数・mixin
@use 'module/variables' as *;
//===============================
//== [ モジュール読み込み ]========
// math（除算に[/]は使えない）
@use "sass:math";
//===============================

/* topMvSlide
----------------------------------------------- */
.topMvSlide {
  margin-bottom: 20px;
  min-height: calc( 486 / 390 * 100vw );
  &__slide {
    position: relative;
    img {
      height: auto;
      width: 100%;
    }
  }
  &__detail {
    position: absolute;
    left: 12px;
    right: 12px;
    bottom: 12px;
    text-align: left;
    z-index: 1;
  }
  &__text {
    @include linkButtonArrow(#000,$pcArrowRect:6);
    display: inline-flex;
    align-items: center;
    gap: 0 10px;
    padding: 12px 16px;
    border: 1px solid #000;
    background-color: #fff;
    box-sizing: border-box;
    &:after {
      flex-shrink: 0;
    }
    span {
      font-size: (math.div(12 , 10 ) * 1rem);
      font-weight: 500;
      line-height: (math.div( 19 , 13 ) * 1em);
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &__listControlButton {
    .topMvSlide &{
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2225.591%22%20height%3D%2249.061%22%20viewBox%3D%220%200%2025.591%2049.061%22%3E%3Cpath%20d%3D%22M-62.04-15726.607l-1.061-1.061%2C23.47-23.472-23.47-23.468%2C1.061-1.061%2C24.53%2C24.528-.53.53Z%22%20transform%3D%22translate(-37.51%20-15726.607)%20rotate(180)%22%20fill%3D%22%23000000%22%2F%3E%3C%2Fsvg%3E");
      //background-position: 1px 2px;
      background-size: calcVw390(14) calcVw390(28);
      width: calcVw390(14);
      height: calcVw390(28);
      &::after{
        content: "";
        display: block;
        width: inherit;
        height: inherit;
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2225.591%22%20height%3D%2249.061%22%20viewBox%3D%220%200%2025.591%2049.061%22%3E%3Cpath%20d%3D%22M-62.04-15726.607l-1.061-1.061%2C23.47-23.472-23.47-23.468%2C1.061-1.061%2C24.53%2C24.528-.53.53Z%22%20transform%3D%22translate(-37.51%20-15726.607)%20rotate(180)%22%20fill%3D%22%23ffffff%22%2F%3E%3C%2Fsvg%3E");
        background-size: calcVw390(14) calcVw390(28);
        position: relative;
        left: 0;
        top: -1px;
      }
    }
    &--prev{
      .topMvSlide &{
        left: calcVw390(8);
        right: auto;
      }
    }
    &--next{
      .topMvSlide &{
        transform: scale(-1);
        left: auto;
        right: calcVw390(8);
        &::after{
          left: 0;
          top: 1px;
        }
      }
    }
  }
  &__pagination {
    .topMvSlide &{
      padding: calcVw390(16) 0 calcVw390(14);
      margin-bottom: 0;
      position: relative;
      bottom: 0;
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }
  .swiper-pagination-bullet {
    height: calcVw390(8);
    width: calcVw390(8);
    border-radius: 50%;
    margin: 0 calcVw390(9*.5);;
  }
  .swiper-pagination-bullet-active {
    background: $zoffBlue;
  }
}