//== [ 共通読み込み ]=============
//変数・mixin
@use 'module/variables' as *;
//===============================
//== [ モジュール読み込み ]========
// math（除算に[/]は使えない）
@use "sass:math";
//===============================

/* topCampaignBar
----------------------------------------------- */
.topCampaignBar{
  position: relative;
  width: 100%;
  margin-bottom: 8px;
  background-color: #f6f6f6;
  &__link{
    height: 45px;
    padding: 0 19px 0;
    font-weight: 500;
    letter-spacing: 0.1rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    &--period {
      margin: 1px 8px 0;
      padding: 2px 8px 3px;
      border: solid 1px;
      font-size: 1.2rem;
    }
    &--title {
      font-size: 1.4rem;
    }
    &--emphasis {
      padding: 0 1px;
      font-size: 2.0rem;
      &::after {
        content: "";
        position: absolute;
        top: 40%;
        right: 8px;
        transform: translateX(-50%) rotate(45deg);
        width: 8px;
        height: 8px;
        border-top: 1px solid #000;
        border-right: 1px solid #000;
      }
    }
  }
}