//== [ 共通読み込み ]=============
//変数・mixin
@use 'module/variables' as *;
//===============================
//== [ モジュール読み込み ]========
// math（除算に[/]は使えない）
@use "sass:math";
//===============================

/* topStoreSearch
----------------------------------------------- */
.topStoreSearch {
    background: $zoffBlue;
    color: #fff;
    &__inner {
        display: flex;
        flex-flow: column-reverse;
    }
    &__body {
        padding: 24px calcVw(32) 40px;
    }
    &__title {
        margin-left: 0;
        &::after {
            background-color: $colorBorderGray;
        }
    }
    &__catch {
        padding-top: 18px;
        font-size: 2rem;
        line-height: math.div(22,20);
    }
    &__text {
        padding-top: 20px;
        font-size: 1.5rem;
        line-height: math.div(22,15);
    }
    &__button {
        min-width: 253px;
    }
}