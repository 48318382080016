//== [ 共通読み込み ]=============
//変数・mixin
@use 'module/variables' as *;
//===============================
//== [ モジュール読み込み ]========
// math（除算に[/]は使えない）
@use "sass:math";
//===============================

/* topCollection
----------------------------------------------- */
.topCollection {
    &__title {
        flex-wrap: wrap;
        &::after {
            top: 10px;
        }
        &--en {
            line-height: math.div(26,40);
        }
        &--ja {
            padding-top: 6px;
            line-height: math.div(18,12);
        }
    }

    //[ Visumo / User ]
    .ecbn-selection-widget {
        margin-top: 0;
        margin-bottom: 0;
        .socialVsHead,.link_gallery {
            display: none;
        }
    }
    .ecbn-selection-page-wrapper {
        // [レイアウト変更]
        .ecbn-selection-snap {
            display: flex;
            gap: 6px;
            width: 100% !important;
            height: auto !important;
            max-width: 100%;
            margin: 0 auto;
            overflow-x: auto;
        }
        // [表示されなくなってしまうのでpositionの解除]
        .ecbn-selection-item {
            position: static !important;
            width: 100% !important;
            height: 167px;
            padding: 0;
            div {
                width: 167px;
                height: 167px;
                overflow: hidden;
            }
            img {
                margin-top: 0;
                transform: unset;
                object-fit: cover;
            }
         }
         .ecbn-selection-content {
            padding:0;
         }
    }

     //[ Visumo / SNAP ]
    .hacobune-top {
        .hacobune-media-post-list-item {
            padding:0;
        }
        .hacobune-app-container {
            padding:0;
        }
        .hacobune-grid-row {
            flex-wrap: nowrap;
            gap: 6px;
            overflow-x: auto;
            scrollbar-width: none;
        }
        .hacobune-grid-col {
            flex: 0 0 167px;
            max-width: 167px;
        }
        .hacobune-media-post-list-item-img-wrapper {
            width: 167px;
        }
        .hacobune-media-post-list-item-img {
            width: 167px;
            height: 167px;
            object-fit: cover;
        }
        .hacobune-logo {
            display: none;
        }
        .hacobune-media-post-list-item-shopname {
            display: flex;
            font-size: 1.3rem;
            line-height: math.div(16,13);
            letter-spacing: 0;
            &::before {
                content: "";
                background: no-repeat center top;
                background-image: url(/img/private/common/header/icon_home.svg);
                background-size: 14px 15px;
                display: -webkit-inline-box;
                display: -ms-inline-flexbox;
                display: inline-flex;
                flex-basis: 20px;
                height: 15px;
                margin-right: 10px;
                width: 14px;
            }
        }
        .hacobune-media-post-list-item-nickname {
            padding-top: 6px;
            font-size: 1.2rem;
            line-height: math.div(14,12);
            &::after {
                content: "さん";
            }
        }
    }
}

// [非表示対応]
.socialVsHead,
.link_gallery,
.snap__head,
.snap__button {
    display: none;
}