//== [ 共通読み込み ]=============
//変数・mixin
@use 'module/variables' as *;
//===============================
//== [ モジュール読み込み ]========
// math（除算に[/]は使えない）
@use "sass:math";
//===============================

/* topLens
----------------------------------------------- */
.topLens {
    background: $colorBg;
    &__list {
            display: flex;
            justify-content: space-between;
            gap: 16px;
            overflow-x: auto;
            min-height: 156px;
            padding-right: calcVw(32);
        &Item {
            position: relative;
            flex: 0 0 200px;
            background: #fff;
            a {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding-bottom: 81px;
            }
            &Body {
                padding-top: 6px;
                padding-right: 24px;
                padding-left: 24px;
            }
            &Face {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 100%;
                min-height: 156px;
            }
            &Text {
                font-size: 1.5rem;
            }
            &Note {
                font-size:1.2rem;
                color:#f53c3c;
            }
            &Arrow {
                display: flex;
                border: 1px solid #000;
                height: 40px;
                width: 40px;
                border-radius: 50%;
                align-items: center;
                justify-content: center;
                position: absolute;
                bottom: 16px;
                right: 16px;
                @include linkButtonCircleArrow(#000,$spArrowRect:5)
            }
        }
    }
}