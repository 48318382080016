//== [ 共通読み込み ]=============
//変数・mixin
@use 'module/variables' as *;
//===============================
//== [ モジュール読み込み ]========
// math（除算に[/]は使えない）
@use "sass:math";
//===============================

/* topVirtualFitting
----------------------------------------------- */
.topVirtualFitting {
    &__title {
        flex-wrap: wrap;
        &--en {
            line-height: math.div(40,26);
        }
        &--ja {
            line-height: math.div(18,12);
        }
        &::after {
            top: 22px;
        }
    }
    &__inner {
        padding-left: 5vw;
        padding-right: 5vw;
    }
    &__face {
        display: flex;
        justify-content: center;
        padding-top: 32px;
    }
}