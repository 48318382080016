//== [ モジュール読み込み ]=============
// math（除算に[/]は使えない）
@use "sass:math";
//===============================

//=====[ フォント/ベースカラー  ]=====

// フォントファミリー
$font-en:'Open Sans', sans-serif;
$font-ja:'Noto Sans JP', sans-serif;
//
// フォントウェイト
$fwLight:300;
$fwReg:400;
$fwMid:500;
$fwSemiBold:600;
$fwBold:700;
$fwBlack:900;
//
// フォント指定
@mixin font-en{
  font-family: $font-en;
  font-weight: $fwSemiBold;
}
//
// カラー
$zoffBlue:#4998DB;
$colorBg: #F6F6F6;
$colorText: #000;
$colorTextGray:#888888;
$colorBorderGray: #E5E5E5;


$colorDelimiterGray: #ebebeb;
$colorAreaGray: #f7f7f7;
$colorLightGray: #ccc;
$colorAttention: #e20c0c;
$colorCaution:#ff0000;

//==================================


//=====[ 数値計算 ]=====
$pc-design-width: 2600; //デザインデータPCサイズ
$sp-design-width: 640; //デザインデータスマホサイズ
$sp-design-width_390: 390; //デザインデータスマホサイズ
// ----
// percent計算式
// * ex)
//   width: calcPer(200,640)
@function calcPer($num1,$num2) {
  @return math.div($num1,  $num2) * 100%;
}
// ----
// vw計算式
// * ex)
//   width: calcVw(200)
@function calcVw($num, $width: $sp-design-width) {
  @return math.div(1vw * $num, $width) * 100;
}
@function calcVw390($num, $width: $sp-design-width_390) {
  @return math.div(1vw * $num, $width) * 100;
}
@function calcVwPC($num, $width: $pc-design-width) {
  //@return ( $w / $pc-design-width ) * 100vw;
  @return math.div(1vw * $num, $width) * 100;
}
@function calcPx($num) {
  @return $num * .5px;
}
//==================================


//=====[ メディアクエリー / レイアウト  ]=====
$layout-width-inner: 768px; // レイアウト幅
$layout-width-inner-sp: 767px; // レイアウト幅
$layout-width-inner-pc: 1080px; // レイアウト幅
$breakpoints: ( // ブレークポイント
  "sp": "screen and (max-width: #{$layout-width-inner-sp})",
  "tab": "screen and (min-width: #{$layout-width-inner-sp}) and (max-width: #{$layout-width-inner - 1px})",
  "notsp": "screen and (min-width: #{$layout-width-inner-sp + 1px })",
);
@mixin mq($breakpoint: sp) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}
// デバイスごとの出し分け
.pc-disp{}
.sp-disp{}
.pcsmall-nondisp{}
.pcsmall-disp{}

@include mq('notsp') {
	.pc-disp{ }
	.sp-disp,.pcsmall-disp{ display: none!important;}
}
@include mq('sp') {
	.sp-disp{ }
	.pc-disp,.pcsmall-disp{ display: none!important;}
}
//==================================


//=====[ アニメーション  ]=====
//
//イージング
//Default
$linear: cubic-bezier(0.250, 0.250, 0.750, 0.750);
$ease: cubic-bezier(0.250, 0.100, 0.250, 1.000);
$ease-in: cubic-bezier(0.420, 0.000, 1.000, 1.000);
$ease-out: cubic-bezier(0.000, 0.000, 0.580, 1.000);
$ease-in-out: cubic-bezier(0.420, 0.000, 0.580, 1.000);
//In
$ease-in-quad: cubic-bezier(0.550, 0.085, 0.680, 0.530);
$ease-in-cubic: cubic-bezier(0.550, 0.055, 0.675, 0.190);
$ease-in-quart: cubic-bezier(0.895, 0.030, 0.685, 0.220);
$ease-in-quint: cubic-bezier(0.755, 0.050, 0.855, 0.060);
$ease-in-sine: cubic-bezier(0.470, 0.000, 0.745, 0.715);
$ease-in-expo: cubic-bezier(0.950, 0.050, 0.795, 0.035);
$ease-in-circ: cubic-bezier(0.600, 0.040, 0.980, 0.335);
$ease-in-back: cubic-bezier(0.600, -0.280, 0.735, 0.045);
//Out
$ease-out-quad: cubic-bezier(0.250, 0.460, 0.450, 0.940);
$ease-out-cubic: cubic-bezier(0.215, 0.610, 0.355, 1.000);
$ease-out-quart: cubic-bezier(0.165, 0.840, 0.440, 1.000);
$ease-out-quint: cubic-bezier(0.230, 1.000, 0.320, 1.000);
$ease-out-sine: cubic-bezier(0.390, 0.575, 0.565, 1.000);
$ease-out-expo: cubic-bezier(0.190, 1.000, 0.220, 1.000);
$ease-out-circ: cubic-bezier(0.075, 0.820, 0.165, 1.000);
$ease-out-back: cubic-bezier(0.175, 0.885, 0.320, 1.275);
//In-Out
$ease-in-out-quad: cubic-bezier(0.455, 0.030, 0.515, 0.955);
$ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1.000);
$ease-in-out-quart: cubic-bezier(0.770, 0.000, 0.175, 1.000);
$ease-in-out-quint: cubic-bezier(0.860, 0.000, 0.070, 1.000);
$ease-in-out-sine: cubic-bezier(0.445, 0.050, 0.550, 0.950);
$ease-in-out-expo: cubic-bezier(1.000, 0.000, 0.000, 1.000);
$ease-in-out-circ: cubic-bezier(0.785, 0.135, 0.150, 0.860);
$ease-in-out-back: cubic-bezier(0.680, -0.550, 0.265, 1.550);

//アニメーションセッティング
@mixin transition ( $sec, $easing , $delay ){
  transition-duration:$sec;
  transition-timing-function: $easing;
  transition-delay: $delay;
}
//==================================

//=====[ Mixin ]====================
//
// [ リンクボタンアロー ]
@mixin linkButtonArrow(
  $color:$colorBorderGray,
  $pcArrowRect:20,
){
  //
  &::after{
    content: "";
    display: inline-flex;
    width: $pcArrowRect*1px;
    height: $pcArrowRect*1px;
    border-color: $color;
    border: 1px solid $color;
    border-left: none;
    border-bottom: none;
    transform: rotate(45deg) translate(0,0);
    @include transition(.4s, ease, 0s);
    will-change: transform;
  }
  &:hover{
    &::after{
     transform: rotate(45deg) translate(30%,-30%);
    }
  }
  
}

@mixin linkButtonCircleArrow(
  $color:$themeColor,
  $spW:128,
  $spArrowW:28,
  $spArrowRect:20,
){
  &::before,&::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
  }
  &::before{
    display: none;//矢印型のとき表示
    height: 1px;
    background-color: $color;
    transform: translate(-50%,-50%);
    width: $spArrowW*1px;
  }
  &::after{
    border: 1px solid $color;
    border-left: none;
    border-bottom: none;
    transform: translate(( -50% - 20% ),-50%) rotate(45deg);
    width: $spArrowRect*1px;
    height: $spArrowRect*1px;
  }
}
//==================================







