//== [ 共通読み込み ]=============
//変数・mixin
@use 'module/variables' as *;
//===============================
//== [ モジュール読み込み ]========
// math（除算に[/]は使えない）
@use "sass:math";
//===============================

/* topCategory
----------------------------------------------- */
.topCategory {
    background: $colorBg;
    &__list {
        display: flex;
        justify-content: space-between;
        gap: 16px;
        padding-right: calcVw(32);
        overflow-x: auto;
        &Item {
            flex: 0 0 200px;
            a {
                display: flex;
                flex-direction: column;
                align-items: center;
                border-bottom: solid 1px #000;
            }
            &Face{
              position: relative;
              &--image{}
              &--text{
                position: absolute;
                left: 0;
                bottom: 0;
                display: block;
                padding: calcVw390(14) calcVw390(16);
                color: #fff;
                text-shadow: 1px 1px 0 #000;
                font-size: calcVw390(15);
                line-height: math.div(22,15);
              }
            }
            &Body {
                display: block;
                width: 100%;
                text-align: center;
                background: #fff;
            }
            &Text {
                font-size: 1.6rem;
                line-height: math.div(40,16);
                font-family: $font-en;
                font-weight: $fwSemiBold;
            }
            img {
                min-height: calc( (math.div(314+16,375)*100%) - 16px );
            }
        }
    }
    &__buttonWrapper {
        padding: 30px 0 0;
    }
    &__button {
        min-width: 250px;
    }
}