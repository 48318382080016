//== [ 共通読み込み ]=============
//変数・mixin
@use 'module/variables' as *;
//===============================
//== [ モジュール読み込み ]========
// math（除算に[/]は使えない）
@use "sass:math";
//===============================

/* topTopics
----------------------------------------------- */
.topTopics {
  &__inner {
      padding-left: calcVw(32);
      padding-right: calcVw(32);
  }
  &__listItemFace {
      display: none;
  }
  &__listItem {
      padding-top: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid $colorBg;
      &:first-child {
          border-top: 1px solid $colorBg;
      }
  }
  &__listItemBody {
      &--date {
          @include font-en;
      }
  }
  &__listItemText {
      padding-top: 10px;
      font-size: 1.5rem;
      line-height: math.div(22,15);
  }
}