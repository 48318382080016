//== [ 共通読み込み ]=============
//変数・mixin
@use 'module/variables' as *;
//===============================
//== [ モジュール読み込み ]========
// math（除算に[/]は使えない）
@use "sass:math";
//===============================

/* topTagCloud
----------------------------------------------- */
.topTagCloud {
    background: $colorBg;
    &__list {
        display: flex;
        flex-wrap: wrap;
        &Item {
            margin-right: 10px;
            margin-bottom: 10px;
            background: #fff;
            a {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 12px 20px;
            }
        }
    }
}