//== [ 共通読み込み ]=============
//変数・mixin
@use 'module/variables' as *;
//===============================
//== [ モジュール読み込み ]========
// math（除算に[/]は使えない）
@use "sass:math";
//===============================

// Functions
@function rem($px) {
  @return math.div( $px , 10 ) * 1rem;
}
@function em($fontsize, $lineheight) {
  @return math.div( $lineheight , $fontsize ) * 1em;
}
@function per($px) {
  @return math.div( $px , 390 ) * 100vw;
}

/* topCollaboration
----------------------------------------------- */
.topCollaboration {
    $Collaboration:'.topCollaboration';
    //
    &__inner {
        position: relative;
    }
    &__title {
        flex-wrap: wrap;
        &--en {
            line-height: math.div(40,26);
        }
        &--ja {
            line-height: math.div(18,12);
        }
    }
    &__list {
        &Item {
            text-align: left;
            &Body {
                position: absolute;
                left: 12px;
                right: 12px;
                bottom: 12px;
                z-index: 1;
            }
            &Text {
                @include linkButtonArrow(#000,$pcArrowRect:5);
                display: inline-flex;
                align-items: center;
                gap: 0 10px;
                padding: 12px 16px;
                max-width: 100%;
                border: 1px solid #000;
                background-color: #fff;
                box-sizing: border-box;
                &:after {
                    flex-shrink: 0;
                }
                span {
                    font-size: rem(12);
                    line-height: line(12, 19);
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
    .swiper-pagination-fraction {
        text-align: left;
        left: 16px;
        bottom: 75px;
        width: auto;
    }
    .swiper-pagination-current {
        font-size: 3.2rem;
        font-family: $font-ja;
        font-weight: $fwBold;
        color: $zoffBlue;
    }
    .swiper-pagination-total {
        font-size: 1.9rem;
    }
    //
    #{$Collaboration}__listControl{ //Swiper用コントロールボタン
      position: absolute;
      right: calcVw390(16);
      top: calcVw390(486);
      width: calcVw390(78);
      height: calcVw390(34);
      &Button{//Prev,Next
        background-image: none;
        margin: 0;
        display: flex;
        border: 1px solid #000;
        height: calcVw390(34);
        width: calcVw390(34);
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        position: absolute;
        @include linkButtonCircleArrow(#000,$spArrowRect:5);
        &--prev{
          transform: scale(-1,1);
          left: 0;
        }
        &--next{
          right: 0;
        }
        &:hover{
          background-color: $zoffBlue;
          border-color: $zoffBlue;
          &::after{
            border-color: #fff;
          }
        }
      }
    }
    //
    &__buttonWrapper {
        padding-top: 66px;
    }
}