//== [ 共通読み込み ]=============
//変数・mixin
@use 'module/variables' as *;
//===============================
//== [ モジュール読み込み ]========
// math（除算に[/]は使えない）
@use "sass:math";
//===============================

/* topAnnounceTicker
----------------------------------------------- */
.topAnnounceTicker {
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  &__button {
    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
    }
    &.swiper-button-next, &.swiper-button-prev {
      width: 10px;
      height: 10px;
      border: none;
      background: none;
      padding: 0;
      margin-top: 0;
      transform: translateY(-50%);
    }
    &--prev {
      &.swiper-button-prev {
        &::before {
          transform: rotate(45deg) scale(-1, -1);
        }
      }
    }
    &--next {
      &.swiper-button-next {
        &::before {
          transform: rotate(45deg);
        }
      }
    }
  }
  &__listItem {
    text-align: left;
    a {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      height: 100%;
      padding-right: 32px;
      padding-left: 16px;
      color: #000;
      font-size: 1.4rem;
      line-height: math.div(21,14);
      text-decoration: none;
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: 16px;
        transform: translateX(-50%) rotate(45deg);
        width: 8px;
        height: 8px;
        border-top: 1px solid #000;
        border-right: 1px solid #000;
      }
    }
  }
  &__list {
    display: flex;
    align-items: center;
  }
  &__inner {
    .swiper-container {
      &::before, &::after {
        content: "";
        display: block;
        width: 40px;
        height: 100%;
        position: absolute;
        top: 0;
        z-index: 9;
        pointer-events: none;
      }
      &::before {
        left: 0;
      }
      &::after {
        right: 0;
        transform: scale(-1, -1);
      }
    }
  }
  //
  // [ SubTop ]
  .zoffsubtop & {
    padding: 20px 0;
  }
  //
}